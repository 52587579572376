.grid-container {
    /*display: grid;
    grid-template-columns: auto auto auto;
    background-color: #2196F3;
    padding: 10px;*/
    vertical-align: middle;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    width: 13.5vw;
    height: 8vw;
    padding: 5px;
    /*font-size: 30px;*/
    /*flex: 0 1 calc(20% - 8px); /* <-- adjusting for margin */
    text-align: center;
  }

  .grid-item-ok {
    background-color: rgba(0, 255, 0, 0.8);
    /*font-size: 30px;*/
  }

  .grid-item-warning {
    background-color: rgb(255, 187, 0);
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    /*font-size: 30px;*/
  }
  

  .grid-item-critical {
    background-color: rgba(255, 0, 0, 0.8);
    animation-name: colorcrit;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    /*font-size: 30px;*/
  }

  html,
body {
	margin : 0;
	padding : 0;
}

@keyframes color {
  0% {
    background-color: rgb(255, 187, 0);
  }
  50% {
    background-color: #ffa600;
  }
  100% {
    background-color: rgb(255, 187, 0);
  }
}

@keyframes colorcrit {
  0% {
    background-color: rgba(255, 0, 0, 0.8);
  }
  50% {
    background-color: #ff3a3aab;
  }
  100% {
    background-color: rgba(255, 0, 0, 0.8);
  }
}